/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import { useTranslation } from "react-i18next";
import { HttpGet, HttpPost } from "../HTTP";
import PropTypes from "prop-types";
import UserContext from "../UserContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Styles from "./PrijemceMojeFotky.module.scss";
import { Button } from "../Components/LinkButton.js";
import FormInput from "../Components/FormInput.js";
import Modal from "../Components/Modal.js";
import Loading from "../Components/Loading.js";
import useAccountLock from "../hooks/useAccountLock.js";

const renderImageURL = (id) =>
  `https://test-andelak.dobryandel.cz:8443/file/Image/${id}`;

const swalConf = {
  timer: 2000,
  toast: true,
  position: "center",
  timerProgressBar: true,
  showConfirmButton: false,
};

const ImageContainer = ({
  Id,
  Description,
  handleAddDescription,
  handleDelete,
  handleClick,
}) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={Styles.imageBlock}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isHover && (
        <div className={Styles.imageControlsContainer}>
          {!Description && 1 === "a" && (
            <span
              className={Styles.imageControl}
              onClick={() => handleAddDescription(Id)}
            >
              <i className="fa-solid fa-comment" />
            </span>
          )}
          <span
            className={Styles.imageControl}
            onClick={() => handleDelete(Id)}
          >
            <i className="fa-solid fa-trash" />
          </span>
        </div>
      )}
      <img
        src={renderImageURL(Id)}
        className={Styles.image}
        alt={Id}
        onClick={() => handleClick()}
      />
      {Description ? (
        <p className={Styles.imageDescription}>{Description}</p>
      ) : (
        <p className={Styles.imageDescriptionMissing}>
          {t("photos-missing-description")}
        </p>
      )}
    </div>
  );
};

const PrijemceMojeFotky = () => {
  const { t } = useTranslation();
  const uploadRef = useRef(null);
  // const { fullUser: user } = useContext(UserContext)
  const fullUser = useContext(UserContext);
  const user = fullUser.fullUser || {};
  const MySwal = withReactContent(Swal);
  const { renderAlert } = useAccountLock({ onlyBeforeWarning: true });

  const [eventType, setEventType] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [file, setFile] = useState(null);
  const [fullSizePhotoURL, setFullSizePhotoURL] = useState(null);
  const monthsCzech = [
    t("month_1"), // January
    t("month_2"), // February
    t("month_3"), // March
    t("month_4"), // April
    t("month_5"), // May
    t("month_6"), // June
    t("month_7"), // July
    t("month_8"), // August
    t("month_9"), // September
    t("month_10"), // October
    t("month_11"), // November
    t("month_12"), // December
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1990 + 1 },
    (_, i) => 1990 + i
  );
  const [month, setMonth] = useState("");
  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };
  const [year, setYear] = useState("");
  const handleYearChange = (e) => {
    setYear(e.target.value);
  };
  const [uploadedPhotoDescription, setUploadedPhotoDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const handleUploadedPhotoDescriptionChange = (e) =>
    setUploadedPhotoDescription(e.target.value);

  useEffect(() => {
    HttpGet("recipientProfile", false, false).then((response) => {
      if (response.error_code !== 200) {
        if (user && user.Images) {
          setPhotos(user.Images);
        }
      } else {
        if (response.response.Images) {
          setPhotos(response.response.Images);
        }
      }
      setIsLoading(false);
    });
  }, [user]);

  const handleImageAddDescription = (e) => {
    setEventType("edit");
    setUploadedPhotoDescription(e.Description);
    setFullSizePhotoURL(renderImageURL(e.Id));
  };
  const handleImageDelete = (e) => {
    setEventType("delete");
    setFullSizePhotoURL(renderImageURL(e.Id));
  };
  const handleImageClick = (e) => {
    setEventType("preview");
    setFullSizePhotoURL(e.Id);
  };
  const handleEventTypeClose = () => {
    setEventType(null);
    setFullSizePhotoURL(null);
    setYear("");
    setMonth("");
    setUploadedPhotoDescription("");
    if (uploadRef.current) uploadRef.current.value = "";
  };
  const handleImageUploadClick = () => uploadRef.current.click();
  const handleUploadPhoto = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setEventType("upload");
        setFullSizePhotoURL(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const onPhotoUpload = () => {
    if (fullSizePhotoURL && file) {
      const formData = new FormData();
      formData.append("Id", user.Id);
      formData.append("File", file);
      const formattedDescription = `${uploadedPhotoDescription}, ${month} ${year}`;
      formData.append("Description", formattedDescription);
      HttpPost(
        "recipientUploadPhoto",
        formData,
        false,
        "multipart/form-data"
      ).then((response) => {
        if (response.error_code !== 200) {
          MySwal.fire({
            title: t("frm-photo-upload-failed"),
            icon: "error",
            ...swalConf,
          });
        } else {
          MySwal.fire({
            title: t("frm-photo-upload-success"),
            icon: "success",
            ...swalConf,
          });
        }
        setEventType(null);
        fullSizePhotoURL(null);
        setYear("");
        setMonth("");
        setUploadedPhotoDescription("");
      });
    }
  };

  const onPhotoEdit = () => {
    if (fullSizePhotoURL) {
      HttpPost("recipient/edit-photo", {
        Id: user.Id,
        Image: fullSizePhotoURL,
        Description: uploadedPhotoDescription,
        Month: month,
        Year: year,
      }).then((response) => {
        if (response.error_code !== 200) {
          MySwal.fire({
            title: t("frm-photo-edit-failed"),
            icon: "error",
            ...swalConf,
          });
        } else {
          MySwal.fire({
            title: t("frm-photo-edit-success"),
            icon: "success",
            ...swalConf,
          });
        }
        setEventType(null);
        setFullSizePhotoURL(null);
        setYear("");
        setMonth("");
        setUploadedPhotoDescription("");
      });
    }
  };

  const onPhotoDelete = () => {
    const me =
      user.Persons.find((person) => person.Id === fullUser.personId) ?? "";
    if (fullSizePhotoURL) {
      HttpPost(`sendEmail`, {
        to: "web@dobryandel.cz",
        subject: `Žádost smazání fotografie`,
        fields: [
          {
            id: "personalNumber",
            title: "Andělské číslo",
            value: user.Id,
          },

          {
            id: "personEmail",
            title: "Email",
            value: me.Mail ?? "-",
          },

          {
            id: "image",
            title: "Adresa obrázku",
            value: fullSizePhotoURL,
          },

          {
            id: "description",
            title: "Odůvodnění",
            value: uploadedPhotoDescription,
          },
        ],
      }).then((resp) => {
        if (resp.error_code !== 200) {
          MySwal.fire({
            title: t("frm-photo-delete-failed"),
            icon: "error",
            ...swalConf,
          });

          return true;
        }

        MySwal.fire({
          title: t("frm-photo-delete-success"),
          icon: "success",
          ...swalConf,
        });
      });
    }
  };

  const eventTypes = {
    preview: {
      title: t("frm-photo-preview-title"),
      button: t("frm-photo-preview"),
      children: (
        <div className={"flex-center"}>
          <img
            src={renderImageURL(fullSizePhotoURL)}
            className={Styles.fullScreenPhoto}
            alt={"fullscreen"}
          />
        </div>
      ),
    },
    upload: {
      title: t("frm-photo-upload-title"),
      button: t("frm-photo-upload"),
      children: (
        <div className={"flex-center"} style={{ flexDirection: "column" }}>
          <img
            src={fullSizePhotoURL}
            className={Styles.fullScreenPhoto}
            alt={"fullscreen-upload"}
          />
          {/* Month Select Input */}
          <div className={Styles.inputWrapper}>
            <select
              name="month"
              onChange={handleMonthChange}
              className={Styles.monthSelect}
              defaultValue=""
            >
              <option value="" disabled>
                {t("frm-photo-select-month")}
              </option>
              {monthsCzech.map((month) => (
                <option value={month} key={month}>
                  {month}
                </option>
              ))}
            </select>

            {/* Year Select Input */}
            <select
              name="year"
              onChange={handleYearChange}
              className={Styles.yearSelect}
              defaultValue=""
            >
              <option value="" disabled>
                {t("frm-photo-select-year")}
              </option>
              {years.map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <FormInput
            type={"textarea"}
            placeholder={t("frm-photo-description")}
            value={uploadedPhotoDescription}
            onChange={handleUploadedPhotoDescriptionChange}
            name={"photo-description"}
            style={{ width: "300px", minHeight: "70px", resize: "none" }}
          />
          <p className={Styles.whatToUpload}>{t("frm-photo-what-to-upload")}</p>
          <Button
            disabled={!month || !year || !uploadedPhotoDescription}
            onClick={() => onPhotoUpload()}
          >
            {t("frm-photo-upload")}
          </Button>
        </div>
      ),
    },
    edit: {
      title: t("frm-photo-edit-title"),
      button: t("frm-photo-edit"),
      children: (
        <div className={"flex-center"} style={{ flexDirection: "column" }}>
          <img
            src={fullSizePhotoURL}
            className={Styles.fullScreenPhoto}
            alt={"fullscreen-upload"}
          />
          {/* Month Select Input */}
          <div className={Styles.inputWrapper}>
            <select
              name="month"
              onChange={handleMonthChange}
              className={Styles.monthSelect}
              defaultValue=""
            >
              <option value="" disabled>
                {t("frm-photo-select-month")}
              </option>
              {monthsCzech.map((month) => (
                <option value={month} key={month}>
                  {month}
                </option>
              ))}
            </select>

            {/* Year Select Input */}
            <select
              name="year"
              onChange={handleYearChange}
              className={Styles.yearSelect}
              defaultValue=""
            >
              <option value="" disabled>
                {t("frm-photo-select-year")}
              </option>
              {years.map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          <FormInput
            type={"textarea"}
            placeholder={t("frm-photo-description")}
            value={uploadedPhotoDescription}
            onChange={handleUploadedPhotoDescriptionChange}
            name={"photo-description"}
            style={{ width: "300px", minHeight: "70px", resize: "none" }}
          />
          <Button
            disabled={!month || !year || !uploadedPhotoDescription}
            onClick={() => onPhotoEdit()}
          >
            {t("frm-photo-edit")}
          </Button>
        </div>
      ),
    },
    delete: {
      title: t("frm-photo-delete-title"),
      button: t("frm-photo-delete"),
      children: (
        <div className={"flex-center"} style={{ flexDirection: "column" }}>
          <img
            src={fullSizePhotoURL}
            className={Styles.fullScreenPhoto}
            alt={"fullscreen-upload"}
          />
          <FormInput
            type={"textarea"}
            placeholder={t("frm-photo-delete-description")}
            value={uploadedPhotoDescription}
            onChange={handleUploadedPhotoDescriptionChange}
            name={"photo-description"}
            style={{ width: "300px", minHeight: "70px", resize: "none" }}
          />
          <Button
            onClick={() => onPhotoDelete()}
            disabled={
              uploadedPhotoDescription !== null &&
              uploadedPhotoDescription.length < 3
            }
          >
            {t("frm-photo-delete")}
          </Button>
        </div>
      ),
    },
  };

  return (
    <LayoutBasic pageTitle={t("prijemce-mojeFotky-title")}>
      {/* HIDDEN INPUT */}
      <input
        type={"file"}
        ref={uploadRef}
        onChange={handleUploadPhoto}
        style={{ display: "none" }}
        accept="image/png, image/jpeg, image/gif"
      />

      <Modal open={eventType !== null} onClose={() => handleEventTypeClose()}>
        {eventTypes[eventType] !== undefined && eventTypes[eventType].children}
      </Modal>

      <div>
        {renderAlert()}
        <div className={Styles.header}>
          <h1 className={Styles.headerText}>Moje fotky</h1>
          <Button onClick={handleImageUploadClick}>{t("photos-upload")}</Button>
        </div>

        {isLoading && <Loading />}

        {!isLoading && photos.length === 0 ? (
          <div className={Styles.errorContainer}>
            <p>{t("photos-none-uploaded")}</p>
          </div>
        ) : (
          <div className={Styles.imageContainer}>
            {photos.map((photo) => (
              <ImageContainer
                {...photo}
                key={photo.Id}
                handleAddDescription={() => handleImageAddDescription(photo)}
                handleDelete={() => handleImageDelete(photo)}
                handleClick={() => handleImageClick(photo)}
              />
            ))}
          </div>
        )}
      </div>
    </LayoutBasic>
  );
};

ImageContainer.propTypes = {
  Id: PropTypes.string.isRequired,
  Description: PropTypes.string,
  handleAddDescription: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default PrijemceMojeFotky;
