import React, { useState, Fragment, useContext } from "react";
//import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import UserContext from "../UserContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import LanguageSwitcher from "../Components/LanguageSwitcher.js";
import "../i18n.js";
import { useTranslation } from "react-i18next";
import { HttpPost } from "../HTTP.js";
import { tokenizeString } from "../Helpers/string-tokenizer.js";
import { getWebLink } from "../appConfig.js";
import CountDown from "../Components/CountDown.js";
import ImgLogo from "../assets/logo.png";
import { formatDateTime } from "../Helpers/dateFormater.js";
import "./Login.scss";
import { isArray } from "lodash";

const PrijemceLogin = () => {
  const { t, i18n } = useTranslation();
  const MySwal = withReactContent(Swal);
  const [username, setUsername] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [phoneId, setPhoneId] = useState("");
  const [transactionId, setTransactionId] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [show2fa, setShow2f] = useState(false);
  const [authIsValidating, setAuthIsValidating] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null)

  const [showSegmentMessage, setShowSegmentMessage] = useState(0);



  const [dateDay, setDateDay] = useState("");
  const [dateMonth, setDateMonth] = useState("");
  const [dateYear, setDateYear] = useState("");

  const user = useContext(UserContext);

  const showPhoneSelect = isArray(phoneNumbers) && phoneNumbers.length > 0;
  const showUsername = !show2fa && !showPhoneSelect;

  const currentDate = new Date()
  const dates = Array.from({ length: 31 }, (_, i) => { return { value: i + 1, label: `${i + 1}` } });
  const months = Array.from({ length: 12 }, (_, i) => { return { value: i + 1, label: t("month_" + (i + 1)) } })
  const years = Array.from({ length: 101 }, (_, i) => { return { value: currentDate.getFullYear() - i, label: currentDate.getFullYear() - i } })

  if (user.isLoggedIn === true) {
    // console.log("user is logged in...", user);
    return <Navigate to="/prijemce" />;
  }

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      onLoginSubmit();
    }
  };

  const handleResetCode = () => {
    setShow2f(false)
    setShowSegmentMessage(2)
    setPhoneId("")
  }

  const segmentMessages = {
    0: <span style={{ textAlign: "left", fontStyle: "italic", fontSize: 12.8 }}>Své číslo příjemce najdete například v některém z e-mailů či dopisů, které jsme vám posílali. Pokud se vám přihlášení nedaří, prosím, zavolejte nám na infolinku <a href="tel:733119119">733-119-119</a>, nebo napište e-mail na <a href="mailto:podpora@dobryandel.cz">podpora@dobryandel.cz</a>.</span>,
    1: <span style={{ textAlign: "left", fontStyle: "italic", fontSize: 12.8 }}>Pokud zde nevidíte své telefonní číslo, nebo se vám přihlášení nedaří, prosím, napište nám na e-mail na <a href="mailto:podpora@dobryandel.cz">podpora@dobryandel.cz</a> své číslo příjemce, kontaktní e-mail a telefonní číslo. Situaci prověříme a co nejdříve se vám ozveme.</span>,
    3: <span style={{ textAlign: "left", fontStyle: "italic", fontSize: 12.8 }}> Nedorazil vám kód nebo již vypršela jeho platnost?{" "}
      <span onClick={handleResetCode} className={"text-primary cursor-pointer"} style={{ textDecoration: "underline" }}>Poslat kód znovu</span>
    </span>
  }

  const onLoginSubmit = () => {
    if (show2fa) return onLoginStep3();
    if (showPhoneSelect) return onLoginStep2();
    return onLoginStep1();
  };

  const onLoginStep1 = () => {
    setAuthIsValidating(true);
    let currentBirthdate = `${dateYear}-${dateMonth}-${dateDay}`;
    if (!birthdate) {
      if (dateDay === "" || dateMonth === "" || dateYear === "") {
        MySwal.fire({
          text: t(`err-${tokenizeString("birthdate-required")}`),
          icon: "error",
          confirmButtonText: t("login-error-ok-button"),
        });
        return
      }
      setBirthdate(currentBirthdate);
    }
    HttpPost(
      "loginRecipient",
      {
        step: "validate",
        personalNumber: username,
        birthDate: !birthdate ? `${dateYear}-${dateMonth}-${dateDay}` : birthdate,
      },
      false
    ).then((resp) => {
      //console.log("login request", resp);

      setAuthIsValidating(false);
      if (resp.error_code !== 200) {
        MySwal.fire({
          text: t(`err-${tokenizeString(resp.error || "unknown")}`),
          icon: "error",
          confirmButtonText: t("login-error-ok-button"),
        });
        return false;
      }
      setShowSegmentMessage(1)
      setPhoneNumbers(resp.response?.Data?.Phones);
    });
  };

  const onLoginStep2 = () => {
    setAuthIsValidating(true);
    if (!birthdate && (dateDay === "" || dateMonth === "" || dateYear === "")) {
      MySwal.fire({
        text: t(`err-${tokenizeString("birthdate-required")}`),
        icon: "error",
        confirmButtonText: t("login-error-ok-button"),
      })
    }

    HttpPost(
      "loginRecipient",
      {
        step: "login",
        personalNumber: username,
        birthDate: birthdate,
        phoneId: phoneId,
      },
      false
    ).then((resp) => {
      // console.log("login request", resp);

      setAuthIsValidating(false);
      if (resp.error_code !== 200) {
        MySwal.fire({
          text: t(`err-${tokenizeString(resp.error || "unknown")}`),
          icon: "error",
          confirmButtonText: t("login-error-ok-button"),
        });
        // verificationCode("")
        return false;
      }
      let currentDate = new Date();
      setTimeLeft(formatDateTime(currentDate))
      setShow2f(true);
      setShowSegmentMessage(3)
      setTransactionId(resp.response.Data.TransactionId);
      // setVerificationCode("")
    });
  };

  const onLoginStep3 = () => {
    // console.log("login request", resp);
    setAuthIsValidating(true);

    HttpPost(
      "loginRecipient",
      {
        step: "verify",
        transactionId: transactionId,
        code: verificationCode,
      },
      false
    ).then((resp) => {
      setAuthIsValidating(false);
      if (resp.error_code === 401) {
        MySwal.fire({
          text: t("prijemce-login-2fa-wrong"),
          icon: "error",
          confirmButtonText: t("prijemce-login-2fa-wrong-button"),
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: t("frm-return-back")
        }).then((result) => {
          if (result.isConfirmed) {
            // Handle confirm button click
            onLoginStep2();
          } else {
            handle2FAFailedStepBack()
          }
        });
        return
      } else if (resp.error_code !== 200) {
        MySwal.fire({
          text: t(`err-${tokenizeString(resp.error || "unknown")}`),
          icon: "error",
          confirmButtonText: t("login-error-ok-button"),
        });
        return false;
      }

      user.setAccessToken(resp.response.token, resp.response.expire);
      user.setUserObject(
        resp.response.scope,
        resp.response.profile,
        resp.response.profile_full,
        resp.response.personId
      );
    });
  };

  const handle2FAFailedStepBack = () => {
    setShow2f(false)
    setPhoneId("")
    setVerificationCode("")
    setShowSegmentMessage(2)
  }

  const onTimeOut = () => {
    MySwal.fire({
      text: t("prijemce-login-timeout"),
      icon: "error",
      showCancelButton: true,
      confirmButtonText: t("prijemce-login-2fa-wrong-button"),
      cancelButtonText: t("frm-return-back"),
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle confirm button click
        onLoginStep2();
      } else if (result.isDismissed) {
        handle2FAFailedStepBack()
      }
    });

  }

  return (
    <Fragment>
      <Helmet>
        <title>{t("header-loginTitle")}</title>
      </Helmet>

      <div className="loginCard">
        <a href={getWebLink(i18n.language)}>
          <img src={ImgLogo} alt="Dobrý Anděl - logo" className="logo" />
        </a>

        <div className="loginCardInner">
          <div>
            <h1 className="title">{t("prijemce-login-title")}</h1>
          </div>

          <section>
            {showUsername && (
              <>
                <div className="field">
                  <label className="label">
                    {t("prijemce-login-username")}
                  </label>
                  <div className={"control has-icons-left"}>
                    <input
                      className="input"
                      disabled={authIsValidating}
                      type="text"
                      autoComplete="username"
                      value={username}
                      placeholder={t("prijemce-login-username")}
                      autoFocus
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                      onKeyDown={onInputKeyDown}
                    />
                    <span className="icon is-small is-left">
                      <i className="fa-regular fa-user" />
                    </span>
                  </div>
                </div>

                <div className={"field mobile-date"}>
                  <label className="label">
                    {t("prijemce-login-birthdate")}
                  </label>
                  <div className={"mobile-date-container"}>
                    <div className={"select"}>
                      <select
                        value={dateDay}
                        onChange={(e) => setDateDay(e.target.value)}
                      >
                        <option value={null}>Den</option>
                        {dates.map((date) => (
                          <option key={date.value} value={date.value}>
                            {date.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={"select"}>
                      <select
                        value={dateMonth}
                        onChange={(e) => setDateMonth(e.target.value)}
                      >
                        <option value={null}>Měsíc</option>
                        {months.map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={"select"}>
                      <select
                        value={dateYear}
                        onChange={(e) => setDateYear(e.target.value)}
                      >
                        <option value={null}>Rok</option>
                        {years.map((year) => (
                          <option key={year.value} value={year.value}>
                            {year.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field full-date">
                  <label className="label">
                    {t("prijemce-login-birthdate")}
                  </label>
                  <div className={"control has-icons-left"}>
                    <input
                      className="input"
                      disabled={authIsValidating}
                      type="date"
                      value={birthdate}
                      placeholder={t("prijemce-login-birthdate")}
                      onChange={(e) => {
                        setBirthdate(e.target.value);
                      }}
                      onKeyDown={onInputKeyDown}
                    />
                    <span className="icon is-small is-left">
                      <i className="fa-regular fa-calendar-days" />
                    </span>
                  </div>
                </div>
              </>
            )}

            {showPhoneSelect && !show2fa && (
              <div className="field">
                <label className="label">
                  {t("prijemce-login-phonenumber")}
                </label>
                <div className={"control select"} style={{ width: "100%" }}>
                  <select
                    className="input"
                    disabled={authIsValidating}
                    value={phoneId}
                    onChange={(value) => {
                      setPhoneId(value.target.value);
                    }}
                  >
                    <option value={null}>Vyberte</option>
                    {phoneNumbers.map((phone) => (
                      <option key={phone.Id} value={phone.Id}>
                        {phone.Phone}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            {show2fa && (
              <div className="field">
                <label className="label">
                  {t("prijemce-login-password-2fa")}
                </label>
                <div className={"timeLeft"}>
                  <p>{t("prijemce-login-timeleft")}</p>
                  <CountDown targetDate={timeLeft} diff={15}
                    format={"mm:ss"}
                    callBack={() => onTimeOut()} />
                </div>
                <div className={"control has-icons-left"}>
                  <input
                    className="input"
                    disabled={authIsValidating}
                    type="text"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    value={verificationCode}
                    autoFocus={true}
                    placeholder={t("prijemce-login-password-2fa")}
                    onChange={(e) => {
                      setVerificationCode(e.target.value);
                    }}
                    onKeyDown={onInputKeyDown}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa-regular fa-lock-alt" />
                  </span>
                </div>
              </div>
            )}
          </section>

          <div className="loginButton">
            <button
              className={
                "button is-rounded" + (authIsValidating ? " is-loading" : "")
              }
              disabled={authIsValidating}
              onClick={onLoginSubmit}
            >
              {t("login-buttonLogin")}
            </button>
          </div>

          <div className="loginLang" style={{ marginTop: 20, display: 'flex', justifyContent: "flex-start", gap: 10 }}>
            <i className="fa-regular fa-info-circle" />
            {segmentMessages[showSegmentMessage] || <></>}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// Login.propTypes = {};
// Login.defaultProps = {}

export default PrijemceLogin;