import React, { useState, useEffect } from "react";
import { HttpGet } from "../HTTP";
import { formatDateTime } from "../Helpers/dateFormater";
import AlertContainer from "../Components/AlertContainer";
import CountDown from "../Components/CountDown";
import { useTranslation } from "react-i18next";

const RELOAD_DURATION = 30;

const useAccountLock = ({ onlyBeforeWarning = false } = {}) => {
  const { t } = useTranslation();
  const [accountStatus, setAccountStatus] = useState({
    isDayWarning: false,
    isLocked: false,
    isWarning: false,
    message: "",
    date: "",
    isDayBeforeLock: false,
  });

  const [futureLockDate, setFutureLockDate] = useState(null);
  const [FutureSplitDatesData, setFutureSplitDatesData] = useState(null);
  const [nextMonthSplitDate, setNextMonthSplitDate] = useState(null);

  function getClosestFutureDate(dates) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const futureDates = dates
      .map((date) => new Date(date))
      .filter((date) => date > currentDate);

    const closestDate = futureDates.reduce((closest, date) => {
      return date < closest ? date : closest;
    }, futureDates[0]);

    return formatDateTime(closestDate, true) || "-";
  }

  const fetchData = async () => {
    try {
      const resp = await HttpGet(
        `valueStore/all?t=${new Date().toISOString()}`,
        false
      );
      const data = resp && resp.response;

      const apiDate =
        data.find((item) => item.id === "recipient_lock_since")?.val || 0;
      // const apiDate = "2024-08-09T06:51:00+02:00";
      const futureLockDates = data
        .find((item) => item.id === "recipient_future_locks_date")
        ?.val.split(",");
      // const futureLockDates =
      //   "2024-07-31,2024-08-30,2024-09-30,2024-10-31,2024-11-29,2024-12-30,2024-07-26,2024-08-21".split(
      //     ","
      //   );
      const futureSplitDates = data
        .find((item) => item.id === "recipient_future_split_date")
        ?.val.split(",");
      // const futureSplitDates =
      //   "2024-08-01,2024-09-02,2024-10-01,2024-11-01,2024-12-02".split(",");
      setFutureSplitDatesData(futureSplitDates);

      const currentDate = new Date();

      const dayAfterCurrent = new Date(currentDate);
      dayAfterCurrent.setDate(dayAfterCurrent.getDate() + 1);
      dayAfterCurrent.setHours(0, 0, 0, 0);

      const isDayBeforeLock =
        futureLockDates.some((date) => {
          const lockDate = new Date(date);
          lockDate.setHours(0, 0, 0, 0);
          return lockDate.getTime() === dayAfterCurrent.getTime();
        }) ||
        futureLockDates.some((date) => {
          const lockDate = new Date(date);
          lockDate.setHours(0, 0, 0, 0);
          const todayDate = new Date();
          todayDate.setHours(0, 0, 0, 0);

          return lockDate.getTime() === todayDate.getTime();
        });

      if (isDayBeforeLock) {
        const lockDate =
          futureLockDates.find((date) => {
            const lockDate = new Date(date);
            lockDate.setHours(0, 0, 0, 0);
            return lockDate.getTime() === dayAfterCurrent.getTime();
          }) ||
          futureLockDates.find((date) => {
            const lockDate = new Date(date);
            lockDate.setHours(0, 0, 0, 0);
            const todayDate = new Date();
            todayDate.setHours(0, 0, 0, 0);

            return lockDate.getTime() === todayDate.getTime();
          }) ||
          null;

        if (lockDate) {
          const lockDateObj = new Date(lockDate);
          const nextMonthYear =
            lockDateObj.getMonth() === 11
              ? lockDateObj.getFullYear() + 1
              : lockDateObj.getFullYear();
          const nextMonthMonth =
            lockDateObj.getMonth() === 11 ? 0 : lockDateObj.getMonth() + 1;
          const nextMonthDate = new Date(nextMonthYear, nextMonthMonth, 1);

          const nextMonthSplitDateValue = futureSplitDates.find((date) => {
            const splitDate = new Date(date);

            return (
              splitDate.getFullYear() === nextMonthDate.getFullYear() &&
              splitDate.getMonth() === nextMonthDate.getMonth()
            );
          });

          setFutureLockDate(new Date(lockDate));
          setNextMonthSplitDate(
            nextMonthSplitDateValue ? new Date(nextMonthSplitDateValue) : null
          );
        }
      }

      if (isDayBeforeLock) {
        setAccountStatus((prev) => {
          return {
            ...prev,
            isDayBeforeLock: true,
          };
        });
      } else {
        setAccountStatus((prev) => {
          return {
            ...prev,
            isDayBeforeLock: false,
          };
        });
      }

      if (apiDate) {
        const lockDate = new Date(apiDate);

        if (currentDate.getDate() === lockDate.getDate()) {
          setAccountStatus((prev) => {
            return {
              ...prev,
              isDayWarning: true,
              isLocked: false,
              isWarning: false,
              message: data.recipient_lock_message,
              date: formatDateTime(lockDate),
            };
          });
        }

        if (data && apiDate && apiDate !== 0 && apiDate !== "0") {
          const lockDatePlusReload = new Date(lockDate);
          lockDatePlusReload.setMinutes(
            lockDatePlusReload.getMinutes() + RELOAD_DURATION
          );

          const now = new Date();

          if (now >= lockDate && now <= lockDatePlusReload) {
            setAccountStatus((prev) => {
              return {
                ...prev,
                isLocked: false,
                isWarning: true,
                isDayWarning: false,
                message: data.recipient_lock_message,
                date: formatDateTime(lockDate),
              };
            });
          } else if (lockDate <= now) {
            setAccountStatus((prev) => {
              return {
                ...prev,
                isLocked: true,
                isDayWarning: false,
                isWarning: false,
                message: data.recipient_lock_message,
                date: formatDateTime(lockDate),
              };
            });
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const renderAlert = () => {
    if (onlyBeforeWarning) {
      return (
        <>
          {accountStatus.isDayBeforeLock && (
            <AlertContainer
              title={t("frm-account-day-before-lock-title")}
              description={t("frm-account-day-before-lock-description", {
                lockDate: futureLockDate
                  ? formatDateTime(futureLockDate, true)
                  : "-",
                unlockDate: nextMonthSplitDate
                  ? formatDateTime(nextMonthSplitDate, true)
                  : "-",
              })}
              status="warning"
              style={{ marginTop: 16, marginBottom: 16 }}
            />
          )}
        </>
      );
    }

    return (
      <>
        {accountStatus.isDayBeforeLock && (
          <AlertContainer
            title={t("frm-account-day-before-lock-title")}
            description={t("frm-account-day-before-lock-description", {
              lockDate: futureLockDate
                ? formatDateTime(futureLockDate, true)
                : "-",
              unlockDate: nextMonthSplitDate
                ? formatDateTime(nextMonthSplitDate, true)
                : "-",
            })}
            status="warning"
            style={{ marginTop: 16, marginBottom: 16 }}
          />
        )}
        {accountStatus.isDayWarning && (
          <AlertContainer
            title={t("frm-account-day-warning-title")}
            description={t("frm-account-day-warning-description")}
            status="warning"
            style={{ marginTop: 16, marginBottom: 16 }}
          />
        )}
        {accountStatus.isLocked && (
          <AlertContainer
            title={t("frm-account-locked-title")}
            description={t("frm-account-locked-description", {
              unlockDate: nextMonthSplitDate
                ? formatDateTime(nextMonthSplitDate, true)
                : "-",
            })}
            status="error"
            style={{ marginTop: 16, marginBottom: 16 }}
          />
        )}
        {accountStatus.isWarning && (
          <AlertContainer
            title={t("frm-account-warning-title")}
            description={t("frm-account-warning-description")}
            status="warning"
            style={{ marginTop: 16, marginBottom: 16 }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <p style={{ fontSize: "0.8em" }}>
                {t("frm-account-locked-date")}{" "}
                <CountDown
                  targetDate={accountStatus.date}
                  format={"mm:ss"}
                  diff={30}
                  style={{ fontWeight: 700 }}
                  callBack={() => requestDataAgain()}
                />
              </p>
            </div>
          </AlertContainer>
        )}
      </>
    );
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, RELOAD_DURATION * 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RELOAD_DURATION]);

  const requestDataAgain = () => {
    fetchData();
  };

  return {
    ...accountStatus,
    requestDataAgain,
    renderAlert,
    unlockDate: FutureSplitDatesData
      ? getClosestFutureDate(FutureSplitDatesData)
      : "-",
  };
};

export default useAccountLock;
